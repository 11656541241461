/**
 * Created by osirvent on 30/03/2017.
 */
angular
    .module('annexaApp')
    .factory('SecFactory',['$rootScope', '$q', '$http', '$filter', 'RestService', 'CommonService', 'Language', 'TableFilter', 'AnnexaModalFactory', 'DTColumnBuilder', 'dialogs', 'globalModals', 'AnnexaFormlyFactory', 'DialogsFactory', 'HelperService', 'SecModals', '$state', 'CustomFieldFactory', 'GlobalDataFactory', 'DccumentsFactory', function($rootScope, $q, $http, $filter, RestService, CommonService, Language, TableFilter, AnnexaModalFactory, DTColumnBuilder, dialogs, globalModals, AnnexaFormlyFactory, DialogsFactory, HelperService, SecModals, $state, CustomFieldFactory, GlobalDataFactory, DccumentsFactory) {
        var factory = {};

        //region Propietats

        factory.organs = [];
        factory.sections = [];
        factory.languageColumn = Language.getActiveColumn();
        factory.users = [];
        factory.meetingLocations = [];
        factory.proposal = {};
        factory.sessionTypes = [];
        factory.session = {};
        factory.secDocumentTypes = [];
        factory.secPresetSignCircuits = [];
        factory.organMemberRols = [];
        factory.organAgendaPointTypes = [];
        factory.secretaries = [];
        factory.newProposalOrgans = [];
        factory.positions = [];
        factory.politicalParties = [];
        factory.thirdsAdded = [];
        factory.newProposalOrgan = undefined;
        factory.secTemplates = [];
        factory.secTemplatesDocumentType = undefined;
        factory.proposalIsInOrder = false;
        factory.proposalSessionsExecuteOrder = undefined;
        factory.secTemplateTypes = [
        	{ id: 'SAC', name: $filter('translate')('global.sec.literals.act'), acronym: 'A'},
        	{ id: 'SCR', name: $filter('translate')('global.sec.literals.certificate'), acronym: 'C'},
        	{ id: 'SCO', name: $filter('translate')('global.sec.literals.announcement'), acronym: 'CO'},
        	{ id: 'SDI', name: $filter('translate')('global.sec.literals.opinion'), acronym: 'DI'},
        	{ id: 'SPA', name: $filter('translate')('global.sec.literals.preact'), acronym: 'PA'},
        	{ id: 'SPR', name: $filter('translate')('global.sec.literals.proposal'), acronym: 'P'},
        	{ id: 'STR', name: $filter('translate')('global.sec.literals.transfer'), acronym: 'T'},
        	{ id: 'SPT', name: $filter('translate')('global.sec.literals.pointExecutedOrder'), acronym: 'PT'}
        ];
        factory.dossierTransactionSec = undefined;
        factory.footerClaims = [];
        factory.proposalTypes = [];
        factory.proposalDossierTransaction = [];
        factory.proposalStates = [
            { id: 'PENDING', name: $filter('translate')('global.sec.literals.status.PENDING'), class: 'text-blue', icon: 'circle-o', style: 'text-blue' },
            { id: 'ACCEPTED', name: $filter('translate')('global.sec.literals.status.ACCEPTED'), class: 'text-blue', icon: 'adjust', style: 'text-blue' },
            { id: 'AMENDMENT', name: $filter('translate')('global.sec.literals.status.AMENDMENT'), class: 'text-danger', icon: 'minus-circle', style: 'text-danger' },
            { id: 'MANAGEMENT', name: $filter('translate')('global.sec.literals.status.MANAGEMENT'), class: 'text-success', icon: 'check-circle', style: 'text-success' },
            { id: 'FINISHED', name: $filter('translate')('global.sec.literals.status.FINISHED'), class: 'text-success', icon: 'dot-circle-o', style: 'text-success' },
            { id: 'CANCELED', name: $filter('translate')('global.sec.literals.status.CANCELLED'), class: 'text-warn', icon: 'times-circle', style: 'text-warn' }
        ];
        factory.sessionStates = [
            { id: 'PENDING', name: $filter('translate')('global.literals.PENDING'), icon: 'circle-o', style: 'text-blue'},
            { id: 'CONVENED', name: $filter('translate')('global.sec.literals.CONVENED'), icon: 'adjust', style: 'text-blue'},
            { id: 'STARTED', name: $filter('translate')('global.sec.literals.STARTED'), icon: 'circle', style: 'text-blue'},
            //{ id: 'CANCELED', name: $filter('translate')('global.literals.CANCELLED'), icon: 'minus-circle', style: 'text-danger' },
            { id: 'EXECUTED', name: $filter('translate')('global.sec.literals.EXECUTED'), icon: 'check-circle', style: 'text-success'},
            { id: 'FINISHED', name: $filter('translate')('global.sec.literals.status.CLOSED'), icon: 'dot-circle-o', style: 'text-success' }
        ];
        factory.votationTypes = [
        	{ id: 'ORDINARY', name: $filter('translate')('global.sec.literals.ordinary')},
        	{ id: 'NOMINAL', name: $filter('translate')('global.sec.literals.nominal')},
        	{ id: 'SECRET', name: $filter('translate')('global.sec.literals.secret')}
        ];
        factory.requiredVoteStates = [
            { id: 'APPROVED_SIMPLE_MAJORITY', name: $filter('translate')('global.sec.literals.voteType.APPROVED_SIMPLE_MAJORITY')},
            { id: 'APPROVED_ABSOLUTE_MAJORITY', name: $filter('translate')('global.sec.literals.voteType.APPROVED_ABSOLUTE_MAJORITY')},
            { id: 'APPROVED_QUALIFIED_TWO_THIRDS', name: $filter('translate')('global.sec.literals.voteType.APPROVED_QUALIFIED_TWO_THIRDS')}
        ];
        
        factory.requiredVoteStatesModify = [
        	{ id: 'NOT_APPROVED', name: $filter('translate')('global.sec.literals.voteType.NOT_APPROVED')},
            { id: 'APPROVED', name: $filter('translate')('global.sec.literals.voteType.APPROVED')}
        ];
        //endregion

        //region Router resolves

        factory.loadBookSec = function() {
            if(factory.organs && factory.organs.length > 0 && factory.sessionTypes && factory.sessionTypes.length > 0){
            	var deferred = $q.defer();
                deferred.resolve("Ok");
                return deferred.promise;
            }else{
	        	var promises = [];
	            promises.push(RestService.findAll('Organ'));
	            promises.push(RestService.findAll('SessionType'));
	            
	            return $q.all(promises)
	                .then(function (data) {
	                	factory.organs = JSOG.decode(data[0].data);
	                	factory.sessionTypes = JSOG.decode(data[1].data);
	                }).catch(function(error) {
	                	factory.organs = [];
	                	factory.sessionTypes = [];
	                });
            }
        }
        
        factory.loadSec = function() {
            var entity = $rootScope.app.configuration.entity;

            var promises = [];

            promises.push(RestService.findAll('Section'));
            promises.push($http({ url: './api/sec/sec_template', method: 'GET' }));
            promises.push(RestService.findAll('Organ'));
            promises.push($http({ url: './api/sec/proposal/secretary_users', method: 'GET' }));

            return $q.all(promises)
                .then(function(data) {
                    factory.sections = $linq(JSOG.decode(data[0].data)).orderBy("x => x." + factory.languageColumn, linq.caseInsensitiveComparer).toArray();
                    factory.secTemplates = JSOG.decode(data[1].data);
                    factory.organs = JSOG.decode(data[2].data);
                    factory.secretaries = JSOG.decode(data[3].data);
                }).catch(function() {
                    factory.sections = [];
                    factory.secTemplates = [];
                    factory.organs = [];
                    factory.secretaries = [];
                })
        }

        factory.loadSecSessions = function() {
            var promises = [];

            promises.push(RestService.findAll('OrganMemberRol'));
            promises.push(RestService.findAll('SessionType'));
            promises.push(RestService.findAll('MeetingLocation'));
            promises.push(RestService.findAll('OrganAgendaPointType'));

            return $q.all(promises)
                .then(function(data) {
                    factory.organMemberRols = JSOG.decode(data[0].data);
                    factory.sessionTypes = JSOG.decode(data[1].data);
                    factory.meetingLocations = JSOG.decode(data[2].data);
                    factory.organAgendaPointTypes = JSOG.decode(data[3].data);
                }).catch(function(error) {
                    factory.organMemberRols = [];
                    factory.sessionTypes = [];
                    factory.meetingLocations = [];
                    factory.organAgendaPointTypes = [];
                });
        }

        factory.loadSecSessionsView = function(id) {
            var promises = [];

            promises.push(RestService.findOne('Session', id));

            return $q.all(promises)
                .then(function (data) {
                    factory.session = JSOG.decode(data[0].data);
                }).catch(function(error) {
                    factory.session = undefined;
                });
        }

        factory.loadSecProposals = function () {
            var promises = [];

            promises.push($http({ url: './api/sec/sec_template/sec_template', method: 'GET' }));

            return $q.all(promises)
                .then(function(data) {
                    var decodedData = JSOG.decode(data[0].data);
                    factory.secDocumentTypes = decodedData.docTypes;
                    factory.secPresetSignCircuits = decodedData.presetSignCircuits;
                }).catch(function(error) {
                    factory.secDocumentTypes = [];
                    factory.secPresetSignCircuits = [];
                })
        }

        factory.loadSecProposalsEdit = function(id) {
            var promises = [];

            promises.push(RestService.findOne('Proposal', id));
            promises.push(RestService.call('./api/sec/proposal/'+id+'/in_orders', 'GET'));
            promises.push(RestService.call('./api/sec/proposal/'+id+'/sessions', 'GET'));
            promises.push(RestService.findAll('FooterClaim'));
            promises.push(RestService.findAll('ProposalType'));
            promises.push(RestService.call('./api/sec/proposal/'+id+'/proposalDossierTransaction', 'GET'));
            return $q.all(promises)
                .then(function(data) {
                    factory.proposal = JSOG.decode(data[0].data);
                    factory.proposalIsInOrder = data[1];
                    factory.proposalSessionsExecuteOrder = data[2];
                    factory.footerClaims = JSOG.decode(data[3].data);
                    factory.proposalTypes = JSOG.decode(data[4].data);
                    factory.proposalDossierTransaction = data[5];
                }).catch(function (error) {
                    factory.proposal = undefined;
                    factory.proposalIsInOrder = false;
                    factory.proposalSessionsExecuteOrder = undefined;
                    factory.footerClaims = [];
                    factory.proposalTypes = [];
                    factory.proposalDossierTransaction = [];
                })
        }
        
        factory.loadSecTram = function() {
            var entity = $rootScope.app.configuration.entity;

            var promises = [];

            promises.push(RestService.findAll('Organ'));

            return $q.all(promises)
                .then(function(data) {
                    factory.organs = JSOG.decode(data[0].data);
                }).catch(function() {
                    factory.organs = [];
                })
        }

        factory.loadAdmin = function(origin) {
            var promises = [];

            if (origin == 'annexa.admin.sec_organs') {
                promises.push(RestService.findAll('MeetingLocation'));
                promises.push($http({ url: './api/sec/sec_template', method: 'GET' }));
                
                return $q.all(promises)
                    .then(function(data) {
                        factory.meetingLocations = JSOG.decode(data[0].data);
                        factory.secTemplates = JSOG.decode(data[1].data);
                    }).catch(function (error) {
                        factory.meetingLocations = [];
                        factory.secTemplates = [];
                    })
            } else if (origin == 'annexa.admin.sec_templates') {
                promises.push($http({ url: './api/sec/sec_template/sec_template', method: 'GET' }));
                promises.push(RestService.findAll('Organ'));
                
                return $q.all(promises)
                    .then(function (data) {
                        var decodedData = JSOG.decode(data[0].data);
                        factory.secDocumentTypes = decodedData.docTypes;
                        factory.secPresetSignCircuits = decodedData.presetSignCircuits;
                        factory.organs = JSOG.decode(data[1].data);
                    }).catch(function (error) {
                        factory.secDocumentTypes = [];
                        factory.secPresetSignCircuits = [];
                        factory.organs = [];
                    })
            } else if (origin == 'annexa.admin.sec_members_organ') {
                promises.push(RestService.findAll('PoliticalParty'));
                promises.push(RestService.findAll('Position'));
                promises.push($http({ url: './api/sec/organ_member_global/thirds_added', method: 'GET' }));
                return $q.all(promises)
                    .then(function (data) {
                        factory.politicalParties = JSOG.decode(data[0].data);
                        factory.positions = JSOG.decode(data[1].data);
                        factory.thirdsAdded = JSOG.decode(data[2].data);
                    }).catch(function (error) {
                        factory.politicalParties = [];
                        factory.positions = [];
                    })
            }
        }
        //endregion

        //region General
        factory.deleteSessionDocument = function(secDocument){
        	var deferred = $q.defer();
            $http({
                url: './api/sec/session/document/'+ secDocument.id,
                method: 'DELETE'
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data))
            }).catch(function(error) {
                deferred.reject(error);
            })

            return deferred.promise;
        }
        
        factory.addProposalOrgan = function(proposalId, organId){
        	var deferred = $q.defer();

            $http({
                url: './api/sec/proposal/' + proposalId + '/organs/'+ organId,
                method: 'POST'
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data))
            }).catch(function(error) {
                deferred.reject(error);
            })

            return deferred.promise;
        }
        
        factory.updateProposalOrgan = function(proposalId, proposalOrganId, newOrganId){
        	var deferred = $q.defer();

            $http({
                url: './api/sec/proposal/' + proposalId + '/organs/' + proposalOrganId +'/'+newOrganId,
                method: 'PUT'
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
        
        factory.deleteProposalOrgan = function(proposalId, removedIds){
        	var deferred = $q.defer();

            $http({
                url: './api/sec/proposal/' + proposalId + '/organs/' + CommonService.getParameterList(removedIds),
                method: 'DELETE'
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
        
        factory.updateStateProposal = function(proposalId, state, secretariatObservations){
        	var deferred = $q.defer();

            $http({
                url: './api/sec/proposal/' + proposalId + '/state/' + state + '/' + secretariatObservations,
                method: 'PUT'
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
           
        factory.createDocumentModal = function(type, oldDocument, object_type, object, promise, multiDocs, proposalOrgan) {
            var deferred = promise ? $q.defer() : null;

            var errorNoTemplates = function() {
                DialogsFactory.error($filter('translate')('global.sec.literals.noTemplates'));

                if(promise) {
                    deferred.reject('error');
                }
            }

            var createDocumentSubmit = function() {
                var self = this;
                var model = this.annexaFormly.model.modal_body;

                if(model) {
                    var request = {
                        secTemplate: { id: model.secTemplate },
                        section: { id: model.section },
                        profile: { id: model.profile },
                        title: model.documentTitle,
                        languageColumn: Language.getActiveColumn(),
                        translations: RestService.getTranslationsForSend(),
                        oldDocument: this.extra.oldDocument,
                        multiDocs:multiDocs,
                        proposalOrgan: proposalOrgan,
                        doctype: { id: model.docType},
                        archiveClassification: ((model.archiveClassification && model.archiveClassification.$selected && model.archiveClassification.$selected.id)?{id: model.archiveClassification.$selected.id}:undefined)
                    };
                    if (!DccumentsFactory.validDocumentName(request.title, model.docType)) {
                    	if(this.alerts){
                    		this.alerts.length = 0;
                    	}else{
                    		this.alerts = [];
                    	}
                    	this.alerts.push({msg: 'global.errors.docNameFormat'});
                    }else{
	                    var cf = CustomFieldFactory.getModelValues(model);
	                    request.customFields = [];
	                    var documentType = $linq(GlobalDataFactory.allDocumentTypes).firstOrDefault(undefined,"x => x.id == "+model.docType);
	        			if(documentType && cf && cf.length > 0) {
	                        _.forEach(documentType.customFields, function(docTypeField) {
	                            var docCustomField = {
	                                customField: { id: docTypeField.customField.id },
	                                required: docTypeField.required,
	                                viewOrder: docTypeField.viewOrder,
	                                value: docTypeField.value,
	                                noEditable: docTypeField.noEditable
	                            };
	
	                            var cfValue = $linq(cf).singleOrDefault(undefined, "x => x.id == 'cf_" + documentType.id  + "_" + docTypeField.customField.id + "'");
	
	                            if(cfValue) {
	                                if(docTypeField.customField.frontendType == 'CHECKBOX'){
	                                    var custom_field_selected = $linq(cf).where(function(x){
	                                        if(x.id.startsWith(cfValue.id+"_")){return true}else{return false}}
	                                    ).toArray();
	                                    if(custom_field_selected && custom_field_selected.length > 0){
	                                        docCustomField.value = '[';
	                                        _.forEach(custom_field_selected, function (item, key) {
	                                            if(item.value) {
	                                                if(docTypeField.customField && docTypeField.customField.listValues && docTypeField.customField.listValues.length > 0) {
	                                                    var custom_field_value_selected = $linq(docTypeField.customField.listValues).where(function(x){
	                                                        if(item.id.endsWith("_"+x.value)){return true}else{return false}}
	                                                    ).toArray();
	                                                    if(custom_field_value_selected && custom_field_value_selected.length > 0){
	                                                        docCustomField.value += ((docCustomField.value == '[') ?custom_field_value_selected[0].value : ',' + custom_field_value_selected[0].value);
	                                                    }
	                                                }
	                                            }
	                                        });
	                                        docCustomField.value += ']';
	                                    }
	                                }else if(Array.isArray(cfValue.value)) {
	                                    if(docTypeField.customField.frontendType == 'MULTIPLESELECT') {
	                                        docCustomField.value = '[';
	
	                                        _.forEach(cfValue.value, function (item) {
	                                            docCustomField.value += ((docCustomField.value == '[') ? item : ',' + item);
	                                        });
	
	                                        docCustomField.value += ']';
	                                    } else {
	                                        docCustomField.value = cfValue.value[0];
	                                    }
	                                } else {
	                                    if(cfValue.value instanceof Date) {
	                                        docCustomField.value = $filter('date')(cfValue.value,'yyyy-MM-ddTHH:mm:ss');
	                                    } else {
	                                        docCustomField.value = cfValue.value;
	                                    }
	                                }
	                            }
	
	                            request.customFields.push(docCustomField);
	                        });
	                    }
	                    if(object_type) {
	                    	if(object.id){
	                    		request[object_type] = {id:object.id};                    		
	                    	}else{
	                    		request[object_type] = {id:object};
	                    	}
	                    }else if(object.proposal){
	                        if(object.proposal.id){
	                        	request.proposal = {id:object.proposal.id};
	                        }else{
	                        	request.proposal = {id:object.proposal};
	                        }
	                    }
	
	                    var result = { request: request, type: type, modal: self, originalObject: ((object.proposal)? object : null)};
	
	                    if(promise) {
	                        deferred.resolve(result);
	                    } else {
	                        $rootScope.$broadcast('annexaSecDocumentFromTemplateCreated', result);
	                    }
                    }
                }
            }

            var documentType = $rootScope.app.configuration.sec_document_types[type];

            if(documentType && documentType.id) {
            	var modal = angular.copy(globalModals.secDocFromTemplate);
                modal.alerts = [];
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
                modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.type = type;
                modal.annexaFormly.fields[0].fieldGroup[1].templateOptions.type = type;
                if(object.hasOwnProperty('section')) {
                    modal.annexaFormly.model.modal_body.section = object.section.id;
                }
                if(object.hasOwnProperty('profile')) {
                    modal.annexaFormly.model.modal_body.profile = object.profile.id;
                }
                if(object.hasOwnProperty('sessionDateFirstConvene')) {
                	modal.annexaFormly.fields[0].fieldGroup[2].templateOptions.sessionDate = object.sessionDateFirstConvene;
                }
                if(object.hasOwnProperty('organ') && object.organ && object.organ.acronym) {
                	modal.annexaFormly.fields[0].fieldGroup[1].templateOptions.organId = object.organ.id;
                	modal.annexaFormly.fields[0].fieldGroup[2].templateOptions.acronymOrgan = object.organ.acronym;
                }
                if(object.hasOwnProperty('archiveClassification')){
                	if(object.archiveClassification && object.archiveClassification.id){
                		var archiveClassificationAux = $linq(GlobalDataFactory.archiveClassifications).firstOrDefault(undefined, "x => x.id == "+object.archiveClassification.id);
                		if(archiveClassificationAux){
	                		modal.annexaFormly.model.modal_body.archiveClassification = {
		        				$selected:{
	                                id: archiveClassificationAux.id,
	                                title: archiveClassificationAux[Language.getActiveColumn()]
	                            }
	                		};
                		}else{
                			modal.annexaFormly.model.modal_body.archiveClassification = {};
                		}
	                }else{
	                	modal.annexaFormly.model.modal_body.archiveClassification = {};
	                }
                    modal.annexaFormly.fields[0].fieldGroup[5].templateOptions.options = CommonService.getTreeData(GlobalDataFactory.archiveClassifications, Language.getActiveColumn());
                    
                }
                modal.annexaFormly.options = {};
                modal.extra = { oldDocument: oldDocument };
                modal.annexaFormly.options.formState = {readOnly: false};

                AnnexaFormlyFactory.showModal("modalCreateSecDocument", modal, createDocumentSubmit, false);
            } else {
                errorNoTemplates();
            }

            if(promise) {
                return deferred.promise;
            }
        }
        //endregion

        //region Sesions
        var sessionOperation = function(id, type, method) {
            return RestService.call('./api/sec/session/' + id + '/' + type, method);
        }

        factory.endSession = function(id) {
            return sessionOperation(id, 'end', 'PUT');
        }

        factory.endSessionWithDate = function(id, endDate) {
            return sessionOperation(id, 'end/' + endDate, 'PUT');
        }

        factory.cancelStartSession = function(id) {
            return sessionOperation(id, 'start', 'DELETE');
        }

        factory.startSessionWithDate = function (id, startDate) {
            return sessionOperation(id, 'start/' + startDate, 'PUT')
        }

        factory.deleteSessionAttendee = function (id) {
            return RestService.call('./api/sec/session_attendee/' + id, 'DELETE');
        }

        factory.getSessionProposalsByType = function(id, type) {
            return RestService.call('./api/sec/session/' + id + '/proposals/' + type, 'GET');
        }
        //endregion

        //region Administració
        factory.addSecFooterClaim = function (model) {
            var deferrend = $q.defer();

            var formData = new FormData();
            angular.forEach(model, function (value, key) {
                formData.append(key, value);
            });

            $http({
                method: 'POST',
                url: './api/sec/footer_claim/footer_claim',
                headers: {'Content-Type': undefined},
                data: formData,
                transformRequest: function(data, headersGetterFunction) {
                    return data;
                }
            }).success(function (data, status) {
                deferrend.resolve(data);
            }).error(function (msg, code) {
                deferrend.reject(msg);
            });

            return deferrend.promise;
        }
        
        factory.addSecTemplate = function (model) {
            var deferrend = $q.defer();

            var formData = new FormData();
            angular.forEach(model, function (value, key) {
                formData.append(key, value);
            });

            $http({
                method: 'POST',
                url: './api/sec/sec_template/sec_template',
                headers: {'Content-Type': undefined},
                data: formData,
                transformRequest: function(data, headersGetterFunction) {
                    return data;
                }
            }).success(function (data, status) {
                deferrend.resolve(data);
            }).error(function (msg, code) {
                deferrend.reject(msg);
            });

            return deferrend.promise;
        }
        //endregion

        return factory;
    }]);